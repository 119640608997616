<template>
	<v-container class="issue-page" fluid>
		<v-row justify="center">
			<v-col cols="12" sm="8" md="6">
				<!-- Issue Details Card -->
				<v-card class="issue-card" outlined>
					<v-card-title>
						<h2>Issue Details</h2>
					</v-card-title>
					<v-card-text>
						<div class="issue-field">
							<strong>User ID:</strong> {{ issue.userId }}
						</div>
						<div class="issue-field">
							<strong>Email:</strong> {{ issue.email }}
						</div>
						<div class="issue-field">
							<strong>Created At:</strong> {{ formatDate(issue.createdAt) }}
						</div>
						<div class="issue-field">
							<strong>Issue:</strong>
							<v-expand-transition>
								<v-card class="issue-text" outlined>
									<div v-html="formatIssueText(issue.issue)" />
								</v-card>
							</v-expand-transition>
						</div>
					</v-card-text>
				</v-card>

				<!-- Reply Section -->
				<v-card class="reply-card" outlined>
					<v-card-title>
						<h3>Reply to this Issue</h3>
					</v-card-title>
					<v-card-text>
						<!-- Reply Textarea -->
						<v-textarea v-model="replyMessage" label="Your Reply" outlined rows="5"
							placeholder="Type your reply here..." :rules="[v => !!v || 'Reply is required']"></v-textarea>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" @click="submitReply">Submit Reply</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>




<script>
import RestResource from "../../..//services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
	data() {
		return {
			issueId: this.$route.params.issueId,
			issue: [],
			replyMessage: "",
		};
	},

	mounted() {
		this.getIssue()
	},

	methods: {
		getIssue() {
			this.$setLoader()
			service.getAllIssues({ issueId: this.issueId }).then((r) => {
				this.issue = r.data.data;
				if (r.data.data.comment) {
					this.replyMessage = r.data.data.comment
				}
			});
			this.$disableLoader()
		},
		formatDate(dateStr) {
			const date = new Date(dateStr);
			return date.toLocaleDateString() + " " + date.toLocaleTimeString();
		},
		formatIssueText(text) {
			if (!text) return '';
			// Replace \n\n.. with <br> for formatting
			return text.replace(/\\n/g, "<br>").replace(/\.\./g, "...");
		},
		submitReply() {
			let data = {
				comment: this.replyMessage,
				issueId: this.issueId
			}
			this.$setLoader()
			service.replyToIssue(data).then((res) => {
				if (res) {
					alert("Successfully added comment");
					this.$router.push({ name: "MobileV2Issues" });
				}
			})
		}
	},
};
</script>

<style scoped>
.issue-card {
	max-width: 100%;
	background-color: #fafafa;
}

.issue-field {
	margin-bottom: 15px;
	font-size: 16px;
	/* Increased text size for issue fields */
}

.issue-text {
	white-space: pre-wrap;
	word-wrap: break-word;
	max-height: 300px;
	/* Increased max height for more visible content */
	overflow-y: auto;
	padding: 15px;
	background-color: #fff;
	font-size: 18px;
	/* Increased text size for the issue description */
	line-height: 1.6;
	/* Improved line spacing for readability */
}

h2 {
	text-align: center;
}

strong {
	color: #333;
	font-size: 18px;
}
</style>